export const sveHvrImenice: string[] = [
  'aerob',
  'afera',
  'afiks',
  'agama',
  'agava',
  'agent',
  'agora',
  'agrar',
  'agrum',
  'akord',
  'aktiv',
  'aktiv',
  'alarm',
  'album',
  'aleja',
  'alibi',
  'alkan',
  'alkar',
  'alken',
  'alkin',
  'aljkav',
  'ambon',
  'ameba',
  'amper',
  'anđeo',
  'anion',
  'anoda',
  'aorta',
  'arbun',
  'arena',
  'argon',
  'arhea',
  'arhiv',
  'arija',
  'aroma',
  'arsen',
  'asket',
  'astat',
  'astma',
  'atelje',
  'atlas',
  'atrij',
  'augur',
  'autor',
  'avion',
  'babić',
  'bacač',
  'bacil',
  'bačva',
  'badanj',
  'badem',
  'badnji',
  'bager',
  'bahat',
  'bajam',
  'bajka',
  'bakar',
  'baklja',
  'balet',
  'balon',
  'balun',
  'banda',
  'banka',
  'barba',
  'barem',
  'barij',
  'barka',
  'barok',
  'barun',
  'barut',
  'basna',
  'batić',
  'bazen',
  'bazga',
  'bazni',
  'bećar',
  'bedem',
  'bedro',
  'berač',
  'berba',
  'beton',
  'bezub',
  'biber',
  'bičaš',
  'bijeg',
  'bijel',
  'bijes',
  'biljeg',
  'biljka',
  'biljni',
  'birač',
  'biser',
  'bista',
  'bitan',
  'bitka',
  'bivol',
  'bivši',
  'bizon',
  'blago',
  'blago',
  'blago',
  'blanja',
  'blato',
  'blizu',
  'blizu',
  'bluza',
  'bočat',
  'bočni',
  'bočno',
  'boćar',
  'bodež',
  'bodlja',
  'bofor',
  'bogac',
  'bogat',
  'bojna',
  'bojni',
  'bolan',
  'bolid',
  'bomba',
  'bongo',
  'borac',
  'borba',
  'borni',
  'borov',
  'borov',
  'bosti',
  'božji',
  'braco',
  'braća',
  'brada',
  'brana',
  'branje',
  'brati',
  'brava',
  'bravo',
  'breka',
  'breme',
  'brend',
  'breza',
  'brico',
  'briga',
  'brklja',
  'brlog',
  'bronh',
  'brtva',
  'brvno',
  'bubanj',
  'bučan',
  'bučno',
  'budan',
  'buhač',
  'bujan',
  'buket',
  'bukov',
  'bukva',
  'bulin',
  'bunar',
  'bunda',
  'burag',
  'buran',
  'burek',
  'burin',
  'burno',
  'burza',
  'busen',
  'bušik',
  'bušin',
  'butan',
  'carev',
  'cedar',
  'cekin',
  'celer',
  'centa',
  'cesta',
  'cezij',
  'cigla',
  'cijel',
  'cijep',
  'cijev',
  'cikas',
  'cikla',
  'ciljni',
  'cimet',
  'cinik',
  'cipal',
  'cista',
  'citat',
  'citra',
  'civil',
  'crkva',
  'crnka',
  'crpka',
  'crtež',
  'čabar',
  'čagalj',
  'čamac',
  'čaplja',
  'časak',
  'časno',
  'čaška',
  'čavao',
  'čavka',
  'čedan',
  'čekić',
  'čekrk',
  'čelik',
  'čelni',
  'čeljad',
  'čemer',
  'čeoni',
  'čepić',
  'često',
  'češalj',
  'češanj',
  'češer',
  'češki',
  'četka',
  'četri',
  'čežnja',
  'čičak',
  'čigra',
  'čiopa',
  'čipka',
  'čislo',
  'čisto',
  'čitač',
  'čitak',
  'čitav',
  'čizma',
  'čokot',
  'čopor',
  'čučanj',
  'čudak',
  'čudan',
  'čudno',
  'čujan',
  'čulan',
  'čupav',
  'čuvar',
  'čvrga',
  'čvrst',
  'ćelav',
  'ćilim',
  'ćuška',
  'dabar',
  'dagnja',
  'dakle',
  'dalek',
  'daljnji',
  'danak',
  'danak',
  'danas',
  'daska',
  'dašak',
  'dativ',
  'datum',
  'davež',
  'davni',
  'davno',
  'debeo',
  'debil',
  'deblo',
  'dečko',
  'dekan',
  'dekor',
  'delta',
  'demon',
  'demos',
  'deran',
  'deset',
  'desni',
  'desni',
  'desno',
  'detalj',
  'devet',
  'devon',
  'dičan',
  'diljem',
  'dinar',
  'dingo',
  'dioba',
  'dioda',
  'diple',
  'dišni',
  'divan',
  'divlji',
  'divno',
  'dizač',
  'dizel',
  'djeca',
  'djelo',
  'dlaka',
  'dobar',
  'dobit',
  'dobni',
  'dobro',
  'dobro',
  'doček',
  'dočim',
  'dodir',
  'dogma',
  'dojam',
  'dojka',
  'dokad',
  'dokaz',
  'dokle',
  'dokon',
  'dokud',
  'dolar',
  'domar',
  'domet',
  'donle',
  'dopis',
  'dosad',
  'doseg',
  'dosje',
  'dosta',
  'dotad',
  'dotle',
  'dotok',
  'dovde',
  'dovle',
  'dovod',
  'dovoz',
  'drača',
  'draga',
  'drago',
  'drama',
  'dreka',
  'droga',
  'drozd',
  'drsko',
  'drška',
  'drugi',
  'drugi',
  'drvce',
  'drvlje',
  'drvni',
  'drzak',
  'držak',
  'dubok',
  'dućan',
  'dudaš',
  'dudov',
  'dugme',
  'duhan',
  'dukat',
  'dupin',
  'duplja',
  'dušik',
  'dušni',
  'dužan',
  'dveri',
  'dvica',
  'dvije',
  'dvoje',
  'dvoji',
  'džudo',
  'đački',
  'đakon',
  'đavao',
  'đeram',
  'đuveč',
  'edikt',
  'efekt',
  'egzem',
  'ekcem',
  'ekipa',
  'ekran',
  'elisa',
  'elita',
  'emajl',
  'endem',
  'enzim',
  'eocen',
  'epika',
  'epoha',
  'epski',
  'ester',
  'estet',
  'etapa',
  'etaža',
  'etika',
  'etnik',
  'eunuh',
  'fagot',
  'fakir',
  'farad',
  'farma',
  'fauna',
  'fazan',
  'felah',
  'fenjer',
  'feral',
  'fetus',
  'fikus',
  'firma',
  'fitilj',
  'fjeld',
  'fjord',
  'flora',
  'flota',
  'fluid',
  'fluor',
  'foaje',
  'fokus',
  'fonem',
  'forma',
  'forum',
  'fosil',
  'foton',
  'fraza',
  'freon',
  'front',
  'frula',
  'futur',
  'gačac',
  'gadan',
  'gajde',
  'galeb',
  'galop',
  'gamad',
  'ganga',
  'garav',
  'garda',
  'garig',
  'gatar',
  'gaučo',
  'gavun',
  'gazda',
  'gejša',
  'genij',
  'genom',
  'geslo',
  'gesta',
  'gibak',
  'gibon',
  'gilda',
  'ginko',
  'glava',
  'glede',
  'glina',
  'gluma',
  'glupo',
  'gljiva',
  'gnoma',
  'gnjida',
  'golać',
  'golem',
  'golet',
  'golub',
  'gomolj',
  'gonič',
  'gorak',
  'gorje',
  'gorko',
  'gornji',
  'gospa',
  'gošća',
  'gotov',
  'govor',
  'gozba',
  'građa',
  'graja',
  'grana',
  'granje',
  'grbav',
  'grčki',
  'grdan',
  'grdno',
  'greda',
  'grejp',
  'grifo',
  'grinja',
  'gripa',
  'griva',
  'griža',
  'grmlje',
  'groza',
  'grozd',
  'grubo',
  'gruda',
  'grudi',
  'grupa',
  'gubar',
  'gubav',
  'gudač',
  'gulag',
  'gulaš',
  'gumar',
  'gumen',
  'gumno',
  'gusak',
  'gusar',
  'guska',
  'gusle',
  'gušav',
  'gutač',
  'gužva',
  'gvalja',
  'habit',
  'haiku',
  'hajde',
  'hajka',
  'haker',
  'harač',
  'harem',
  'harfa',
  'hašiš',
  'helij',
  'helot',
  'heljda',
  'henri',
  'heroj',
  'hidra',
  'hihot',
  'himba',
  'himna',
  'hitac',
  'hitan',
  'hitar',
  'hitin',
  'hitno',
  'hitnja',
  'hitro',
  'hlače',
  'hodža',
  'hokej',
  'horda',
  'hotel',
  'hrana',
  'hrast',
  'hrčak',
  'hrušt',
  'humak',
  'human',
  'humlje',
  'humor',
  'humus',
  'hunta',
  'hvala',
  'hvoja',
  'ičiji',
  'ideal',
  'ideja',
  'idila',
  'idiom',
  'idiot',
  'idući',
  'igdje',
  'igrač',
  'ikada',
  'ikako',
  'ikoji',
  'ikona',
  'ikuda',
  'imanje',
  'imanje',
  'imati',
  'imela',
  'inače',
  'inćun',
  'irski',
  'ishod',
  'iskaz',
  'iskon',
  'iskra',
  'islam',
  'ispad',
  'ispis',
  'ispit',
  'ispod',
  'istek',
  'istok',
  'istom',
  'ivrit',
  'izaći',
  'izbor',
  'izdah',
  'izgon',
  'izići',
  'izlaz',
  'izlet',
  'izlog',
  'izljev',
  'izmet',
  'iznad',
  'iznos',
  'izraz',
  'izrez',
  'izrod',
  'izuti',
  'izvan',
  'izvod',
  'izvor',
  'izvoz',
  'jadan',
  'jahač',
  'jahta',
  'jajni',
  'jakna',
  'jalov',
  'jamac',
  'janjad',
  'jarac',
  'jarad',
  'jarak',
  'jaram',
  'jarić',
  'jarki',
  'jasan',
  'jasen',
  'jasle',
  'jasno',
  'javni',
  'javor',
  'jecaj',
  'ječam',
  'jedak',
  'jedan',
  'jedar',
  'jedni',
  'jedno',
  'jedro',
  'jedva',
  'jelen',
  'jelka',
  'jelov',
  'jesen',
  'jesti',
  'jetra',
  'jetra',
  'jezik',
  'jeziv',
  'ježić',
  'jidiš',
  'jodni',
  'jodov',
  'jučer',
  'junac',
  'junad',
  'junak',
  'juriš',
  'jurta',
  'jušni',
  'juten',
  'jutro',
  'jutro',
  'južni',
  'kabao',
  'kabel',
  'kaćun',
  'kadar',
  'kadar',
  'kadar',
  'kadet',
  'kafić',
  'kajak',
  'kajda',
  'kakao',
  'kakav',
  'kalež',
  'kalif',
  'kalij',
  'kalup',
  'kamen',
  'kanal',
  'kanon',
  'kanta',
  'kanjon',
  'kapak',
  'kaplja',
  'kaput',
  'karat',
  'karta',
  'kasni',
  'kasno',
  'kasta',
  'kašalj',
  'katar',
  'katni',
  'kavez',
  'kavga',
  'kazna',
  'kebab',
  'kefir',
  'keson',
  'kicoš',
  'kičma',
  'kićen',
  'kifla',
  'kilav',
  'kiosk',
  'kipar',
  'kiseo',
  'kisik',
  'kišan',
  'kišni',
  'klada',
  'klanje',
  'klapa',
  'klapa',
  'klasa',
  'klati',
  'klaun',
  'kleka',
  'kleti',
  'klica',
  'klima',
  'klopa',
  'klupa',
  'kljast',
  'kljova',
  'kljuse',
  'knjiga',
  'koala',
  'kobac',
  'koban',
  'kobra',
  'kocka',
  'kočar',
  'koćar',
  'kojot',
  'kokos',
  'kokoš',
  'kokot',
  'kolac',
  'kolač',
  'kolaž',
  'kolik',
  'kolni',
  'kolon',
  'kolos',
  'kolut',
  'koljač',
  'komad',
  'kombi',
  'komet',
  'konac',
  'konac',
  'konak',
  'konge',
  'konop',
  'konto',
  'konjak',
  'konjic',
  'konjić',
  'kopač',
  'kopar',
  'kopča',
  'koplje',
  'kopno',
  'korak',
  'koral',
  'koralj',
  'korov',
  'korzo',
  'kosac',
  'košer',
  'košnja',
  'kotac',
  'kotač',
  'kotao',
  'kotar',
  'kovač',
  'kozji',
  'kozle',
  'kožni',
  'krađa',
  'kramp',
  'kraul',
  'krava',
  'krčma',
  'kreda',
  'krema',
  'kreol',
  'krhak',
  'krilo',
  'kriti',
  'krivo',
  'kriza',
  'križa',
  'krmni',
  'krmni',
  'kroza',
  'krpelj',
  'krsni',
  'krški',
  'kršni',
  'kruna',
  'krvav',
  'krvni',
  'kubik',
  'kubni',
  'kućni',
  'kugla',
  'kuhan',
  'kuhar',
  'kukac',
  'kukma',
  'kukolj',
  'kulak',
  'kulon',
  'kumče',
  'kumin',
  'kunić',
  'kunjad',
  'kunjka',
  'kupac',
  'kupač',
  'kupelj',
  'kupka',
  'kupnja',
  'kupnja',
  'kupon',
  'kupus',
  'kušnja',
  'kutak',
  'kvaka',
  'kvant',
  'kvarc',
  'kvart',
  'kvrga',
  'labav',
  'labud',
  'lađar',
  'lagan',
  'lahor',
  'lakaj',
  'lakat',
  'lakom',
  'lampa',
  'lanac',
  'lanad',
  'lapor',
  'larva',
  'laser',
  'lasta',
  'lavež',
  'lavlji',
  'lavor',
  'lažan',
  'lažno',
  'leden',
  'leđni',
  'leglo',
  'lemeš',
  'lemur',
  'lenta',
  'letač',
  'letak',
  'letni',
  'levit',
  'ležaj',
  'lični',
  'lignja',
  'lihva',
  'lijek',
  'lijen',
  'lijep',
  'lijes',
  'liker',
  'limar',
  'limen',
  'limes',
  'limfa',
  'limun',
  'linđo',
  'linjak',
  'lipanj',
  'lipid',
  'lipov',
  'lisac',
  'liska',
  'lisni',
  'lista',
  'lišaj',
  'lišce',
  'lišće',
  'litij',
  'litra',
  'logor',
  'lojni',
  'lokot',
  'lokva',
  'lonac',
  'lopoč',
  'lopov',
  'lopta',
  'losos',
  'lovac',
  'lovka',
  'lovni',
  'lovor',
  'lozni',
  'lozov',
  'ložač',
  'lubin',
  'lučki',
  'luđak',
  'lugar',
  'lukav',
  'lupa²',
  'lutak',
  'lutka',
  'lutnja',
  'ljanos',
  'ljetni',
  'ljetos',
  'ljevač',
  'ljevak',
  'ljigav',
  'ljiljan',
  'ljubak',
  'ljubav',
  'ljupko',
  'ljuska',
  'ljutit',
  'ljutnja',
  'mačak',
  'mačić',
  'mačji',
  'mačka',
  'madež',
  'magla',
  'magma',
  'majka',
  'major',
  'majur',
  'makar',
  'makar',
  'malen',
  'malko',
  'mamac',
  'mamut',
  'mango',
  'manjak',
  'marka',
  'marva',
  'maser',
  'masiv',
  'maska',
  'maslo',
  'masni',
  'mason',
  'mašta',
  'mazga',
  'maziv',
  'meden',
  'medij',
  'medni',
  'međaš',
  'mekan',
  'meket',
  'melem',
  'melez',
  'menta',
  'menza',
  'mesar',
  'mesni',
  'mesti',
  'metak',
  'metal',
  'metan',
  'metar',
  'metež',
  'metil',
  'metilj',
  'metla',
  'metro',
  'mijau',
  'mijeh',
  'minij',
  'minus',
  'miran',
  'miraz',
  'miris',
  'mirno',
  'mirta',
  'misal',
  'misao',
  'misni',
  'mišić',
  'mišić',
  'mišji',
  'miška',
  'mitra',
  'mjera',
  'mlada',
  'mlado',
  'mlaka',
  'mljeti',
  'mniti',
  'mnogi',
  'mnogo',
  'mnome',
  'moćan',
  'modar',
  'model',
  'modem',
  'modni',
  'moguć',
  'mokar',
  'molba',
  'moljac',
  'momak',
  'monah',
  'moral',
  'mosni',
  'mošnja',
  'motel',
  'motet',
  'motiv',
  'motka',
  'motor',
  'mozak',
  'možda',
  'mrena',
  'mreža',
  'mrkli',
  'mrkva',
  'mršav',
  'mrtav',
  'mrzak',
  'mrzao',
  'mržnja',
  'mučan',
  'mučno',
  'mudar',
  'mukao',
  'muklo',
  'mulat',
  'murva',
  'musav',
  'musti',
  'muški',
  'mutan',
  'mutav',
  'muzej',
  'mužar',
  'mužev',
  'mužnja',
  'naboj',
  'nabor',
  'nacrt',
  'načas',
  'način',
  'nafta',
  'nagao',
  'nagib',
  'naglo',
  'nagon',
  'nahod',
  'naići',
  'naime',
  'naiva',
  'najam',
  'nakit',
  'nakon',
  'nalaz',
  'nalet',
  'nalik',
  'nalog',
  'naljev',
  'namaz',
  'namet',
  'namot',
  'nandu',
  'nanos',
  'naoko',
  'napad',
  'napet',
  'napis',
  'napoj',
  'napon',
  'napor',
  'narav',
  'narod',
  'nasad',
  'nasip',
  'nauka',
  'navod',
  'navoj',
  'nazad',
  'nazal',
  'naziv',
  'nazor',
  'nažao',
  'nećak',
  'nehaj',
  'nekoć',
  'neman',
  'nemar',
  'nemio',
  'nemir',
  'nemoć',
  'nepar',
  'nepce',
  'nerad',
  'nered',
  'nesit',
  'nesti',
  'nešto',
  'netko',
  'netom',
  'nevin',
  'neživ',
  'nijem',
  'nikal',
  'nimfa',
  'nisko',
  'nišan',
  'ništa',
  'ništa',
  'nitko',
  'nizak',
  'noćas',
  'noćca',
  'noćni',
  'nokat',
  'nomad',
  'nonet',
  'norma',
  'nosač',
  'nosat',
  'nosiv',
  'nosni',
  'nošnja',
  'notes',
  'notni',
  'novac',
  'novak',
  'nožni',
  'nujan',
  'nulti',
  'nužda',
  'nužno',
  'njedra',
  'njegov',
  'njezin',
  'nježan',
  'nježno',
  'njihov',
  'njisak',
  'njiska',
  'njuška',
  'obala',
  'obdan',
  'obići',
  'obilje',
  'obiti',
  'objed',
  'oblak',
  'oblić',
  'oblik',
  'oblog',
  'obnoć',
  'oboje',
  'obrat',
  'obraz',
  'obred',
  'obris',
  'obrok',
  'obrub',
  'obruč',
  'obrva',
  'obuća',
  'obući',
  'obuka',
  'obuti',
  'obzir',
  'obzor',
  'ocean',
  'očica',
  'očito',
  'očnjak',
  'odaja',
  'odati',
  'odbor',
  'odgoj',
  'odjek',
  'odjel',
  'odljev',
  'odmah',
  'odmak',
  'odmor',
  'odnos',
  'odora',
  'odraz',
  'odred',
  'odron',
  'odsad',
  'odveć',
  'odvod',
  'odvoz',
  'odziv',
  'oglas',
  'ogled',
  'oklop',
  'okolo',
  'okolo',
  'okret',
  'okrug',
  'oksid',
  'oktan',
  'oktet',
  'okuka',
  'okvir',
  'olako',
  'olein',
  'olovo',
  'oltar',
  'oluja',
  'omara',
  'omjer',
  'omlet',
  'onako',
  'onamo',
  'ondje',
  'oniks',
  'onuda',
  'opeći',
  'opeka',
  'opera',
  'ophod',
  'opiti',
  'opkop',
  'oprez',
  'opseg',
  'optok',
  'orada',
  'oranje',
  'orati',
  'organ',
  'orkan',
  'ormar',
  'oruđe',
  'oseka',
  'osinji',
  'osjet',
  'oslad',
  'oslić',
  'osmak',
  'osoba',
  'osuda',
  'osvit',
  'osvrt',
  'oštar',
  'oštro',
  'otada',
  'otava',
  'oteći',
  'oteći',
  'oteti',
  'otići',
  'otkad',
  'otkaz',
  'otkos',
  'otkud',
  'otkup',
  'otpad',
  'otpor',
  'otrov',
  'otrti',
  'otući',
  'otvor',
  'ovako',
  'ovamo',
  'ovčar',
  'ovčji',
  'ovdje',
  'oveći',
  'ovjes',
  'ovrha',
  'ovuda',
  'ozimi',
  'pačad',
  'pačić',
  'pačji',
  'padež',
  'pajac',
  'pakao',
  'paket',
  'palac',
  'palma',
  'paljba',
  'pamet',
  'pampa',
  'pamuk',
  'panda',
  'papak',
  'papar',
  'papir',
  'paran',
  'parni',
  'pasat',
  'pasiv',
  'pasji',
  'pasta',
  'pasti',
  'pasti',
  'patak',
  'patka',
  'patnja',
  'pauza',
  'pažnja',
  'pčela',
  'pečat',
  'pečen',
  'pedalj',
  'pegla',
  'pehar',
  'pekar',
  'pelin',
  'pelud',
  'peljar',
  'penat',
  'penis',
  'pepeo',
  'perač',
  'perad',
  'perce',
  'perje',
  'perla',
  'peron',
  'pešće',
  'petak',
  'petlja',
  'petni',
  'pijan',
  'pijuk',
  'pilad',
  'pilar',
  'pilot',
  'piljak',
  'pipac',
  'pipak',
  'pirat',
  'pisac',
  'pisač',
  'pisak',
  'pisar',
  'pismo',
  'pista',
  'pitak',
  'pitom',
  'piton',
  'pizza',
  'pjega',
  'pjena',
  'plaća',
  'plast',
  'plašt',
  'plato',
  'plaža',
  'pleća',
  'pleme',
  'plima',
  'ploča',
  'ploha',
  'plova',
  'pluća',
  'pluće',
  'pluto',
  'podao',
  'podlo',
  'podne',
  'podni',
  'podno',
  'poema',
  'poeta',
  'pogon',
  'pohod',
  'pojam',
  'pojas',
  'pokaz',
  'pokoj',
  'pokop',
  'pokus',
  'polen',
  'polet',
  'polip',
  'polis',
  'polka',
  'poloj',
  'pomak',
  'pomno',
  'pomoć',
  'pomor',
  'ponad',
  'ponoć',
  'ponor',
  'ponos',
  'popis',
  'poput',
  'poraz',
  'pored',
  'porez',
  'poriv',
  'porod',
  'porok',
  'porta',
  'posao',
  'posto',
  'posve',
  'pošip',
  'pošta',
  'pošto',
  'potez',
  'potok',
  'potom',
  'potop',
  'pouka',
  'povez',
  'povik',
  'povod',
  'povoj',
  'povrh',
  'poziv',
  'pozor',
  'požar',
  'pralja',
  'pranje',
  'prase',
  'pravi',
  'pravo',
  'preča',
  'preda',
  'pređa',
  'preko',
  'prelja',
  'prema',
  'preša',
  'priča',
  'prići',
  'prije',
  'prije',
  'princ',
  'prior',
  'prišt',
  'prkos',
  'prljav',
  'proba',
  'proći',
  'proso',
  'prost',
  'proza',
  'prsni',
  'pruće',
  'pruga',
  'pseći',
  'pseto',
  'psiha',
  'psina',
  'pšeno',
  'ptica',
  'pucanj',
  'pučki',
  'puder',
  'puhač',
  'pumpa',
  'punac',
  'punđa',
  'punjač',
  'pupak',
  'puran',
  'pusta',
  'pušač',
  'puška',
  'putar',
  'putem',
  'putem',
  'puten',
  'puzav',
  'pužev',
  'pužji',
  'rabin',
  'račić',
  'račji',
  'račun',
  'račve',
  'radar',
  'radič',
  'radij',
  'radin',
  'radio',
  'radni',
  'radnja',
  'rafal',
  'ragbi',
  'rahao',
  'rakun',
  'rampa',
  'ranac',
  'ranjiv',
  'rasan',
  'rasni',
  'rasol',
  'rasti',
  'rašlje',
  'ratar',
  'ratni',
  'ravan',
  'ravno',
  'razni',
  'razum',
  'ražanj',
  'rebro',
  'rebus',
  'redak',
  'redar',
  'redni',
  'redom',
  'regal',
  'reket',
  'relej',
  'reljef',
  'remen',
  'repaš',
  'repni',
  'repni',
  'retor',
  'reuma',
  'rever',
  'rezak',
  'režanj',
  'režim',
  'ribar',
  'ribež',
  'ribič',
  'ribiz',
  'riblji',
  'rijas',
  'riječ',
  'rikša',
  'rilce',
  'ritam',
  'rizik',
  'rizom',
  'rižin',
  'roblje',
  'robni',
  'robot',
  'ročni',
  'rodan',
  'rodni',
  'rođak',
  'rogač',
  'rogat',
  'rogoz',
  'roker',
  'roman',
  'romon',
  'rondo',
  'rosan',
  'rotor',
  'rovac',
  'rovka',
  'rubac',
  'rubalj',
  'rubin',
  'rublja',
  'rublje',
  'rubni',
  'ručak',
  'ručka',
  'ručni',
  'rudar',
  'rudni',
  'ruglo',
  'rujan',
  'rujan',
  'rukav',
  'rulet',
  'rumba',
  'rumen',
  'ruski',
  'rutav',
  'ružan',
  'ružno',
  'rzati',
  'sablja',
  'sabor',
  'sačma',
  'sadnja',
  'sadra',
  'sahel',
  'sajam',
  'sakat',
  'salaš',
  'salon',
  'salpa',
  'santa',
  'sanjar',
  'sanjke',
  'sapun',
  'sarma',
  'satir',
  'sauna',
  'savez',
  'sažet',
  'scena',
  'sedam',
  'sedef',
  'sedlo',
  'sedmi',
  'sedra',
  'sekta',
  'seljak',
  'senat',
  'seoba',
  'serum',
  'sfera',
  'shema',
  'sidro',
  'sijač',
  'sijed',
  'silan',
  'silno',
  'silom',
  'silos',
  'silur',
  'sinak',
  'sinoć',
  'sinod',
  'sinus',
  'sipak',
  'sirak',
  'sirov',
  'sirup',
  'sisal',
  'sitan',
  'sjati',
  'sječa',
  'sjeći',
  'sjeme',
  'sjena',
  'sjeta',
  'skela',
  'skica',
  'skija',
  'sklad',
  'sklon',
  'sklop',
  'skori',
  'skoro',
  'skrab',
  'skupa',
  'skupo',
  'skuša',
  'slabo',
  'slama',
  'slanje',
  'slast',
  'slati',
  'slava',
  'sleng',
  'slika',
  'slina',
  'sloga',
  'slovo',
  'sluga',
  'sljeme',
  'smeće',
  'smilje',
  'smion',
  'smjel',
  'smjer',
  'smoći',
  'smola',
  'smrad',
  'snaći',
  'snaga',
  'snaha',
  'sniti',
  'sobar',
  'sobni',
  'sobom',
  'sočan',
  'sokol',
  'sonda',
  'sonet',
  'sorta',
  'sorus',
  'speći',
  'spilja',
  'spjev',
  'splav',
  'splet',
  'spona',
  'spora',
  'sporo',
  'sport',
  'spram',
  'sprud',
  'spust',
  'srdit',
  'sreća',
  'srpanj',
  'stado',
  'staja',
  'stanje',
  'start',
  'stati',
  'staza',
  'steći',
  'stega',
  'stela',
  'stepa',
  'stići',
  'stoga',
  'stoka',
  'stopa',
  'stoti',
  'strah',
  'stran',
  'stres',
  'stric',
  'strip',
  'strka',
  'strog',
  'stroj',
  'strop',
  'strti',
  'strug',
  'struk',
  'stuba',
  'stvar',
  'stvor',
  'sućut',
  'sudac',
  'sudar',
  'sudnji',
  'suđen',
  'suita',
  'sukno',
  'suknja',
  'sukob',
  'sukut',
  'sumnja',
  'sunce',
  'supka',
  'surla',
  'surov',
  'sušan',
  'suton',
  'sutra',
  'suzan',
  'suzni',
  'sužanj',
  'svađa',
  'svaki',
  'svast',
  'sveza',
  'svila',
  'svinja',
  'sviti',
  'svjež',
  'svota',
  'svrab',
  'svrha',
  'svući',
  'svuda',
  'šabat',
  'šačni',
  'šakač',
  'šakal',
  'šaljiv',
  'šaman',
  'šamar',
  'šamot',
  'šansa',
  'šapat',
  'šaran',
  'šaren',
  'šašav',
  'šator',
  'šećer',
  'šegrt',
  'šepav',
  'šerif',
  'šesti',
  'šesto',
  'šešir',
  'šetač',
  'šetnja',
  'šiblje',
  'šifra',
  'šiljak',
  'šiljat',
  'šipak',
  'šipka',
  'širok',
  'širom',
  'šiška',
  'šiške',
  'šivač',
  'škamp',
  'škare',
  'škljoc',
  'škola',
  'škrga',
  'škrob',
  'škrto',
  'škver',
  'šljiva',
  'šljuka',
  'šogor',
  'šojka',
  'špaga',
  'špilja',
  'šport',
  'štaka',
  'štala',
  'štand',
  'štene',
  'šteta',
  'štivo',
  'štuka',
  'šugav',
  'šuman',
  'šumar',
  'šumni',
  'šumor',
  'šunka',
  'šupalj',
  'šurla',
  'šuška',
  'šutke',
  'šutnja',
  'švelja',
  'šverc',
  'taban',
  'tabla',
  'tabor',
  'tačke',
  'tajan',
  'tajga',
  'tajna',
  'tajni',
  'tajno',
  'takav',
  'taksa',
  'taksi',
  'talac',
  'talas',
  'talog',
  'taman',
  'tanad',
  'Tanah',
  'tanak',
  'tango',
  'tanko',
  'tanjur',
  'tapir',
  'tašta',
  'tavan',
  'tečaj',
  'tečan',
  'tečno',
  'tekst',
  'telad',
  'tema¹',
  'temelj',
  'tempo',
  'tenis',
  'tenor',
  'tepih',
  'terca',
  'teren',
  'teret',
  'terme',
  'teror',
  'tesar',
  'tesla',
  'teško',
  'tetak',
  'tetka',
  'težak',
  'težak',
  'težnja',
  'tifus',
  'tigar',
  'tijek',
  'tikov',
  'tikva',
  'tilda',
  'tinta',
  'tipka',
  'tisak',
  'tjeme',
  'tkalja',
  'tkanje',
  'tkati',
  'tkivo',
  'tlaka',
  'tmina',
  'tobom',
  'točan',
  'točka',
  'točno',
  'tokar',
  'tolik',
  'tonik',
  'topao',
  'topiv',
  'toplo',
  'topot',
  'toranj',
  'torba',
  'torta',
  'torzo',
  'totem',
  'tovar',
  'traka',
  'trava',
  'trčak',
  'trčka',
  'treći',
  'trema',
  'trenje',
  'trica',
  'triko',
  'trkom',
  'trnci',
  'troje',
  'troji',
  'tromb',
  'tropi',
  'trpak',
  'trpni',
  'trsje',
  'trska',
  'truba',
  'trunje',
  'trupa',
  'trzaj',
  'tubus',
  'tučak',
  'tuđin',
  'tuljac',
  'tuljan',
  'tumač',
  'tumor',
  'tumul',
  'tunel',
  'tupan',
  'tužan',
  'tužba',
  'tužno',
  'ubiti',
  'ubrus',
  'učenje',
  'učiti',
  'udaja',
  'udati',
  'udica',
  'udjel',
  'ufanje',
  'uglas',
  'uglat',
  'ugled',
  'ugljen',
  'ugljik',
  'ugoda',
  'ugriz',
  'uhoda',
  'ukras',
  'ukriž',
  'ulica',
  'uliti',
  'uloga',
  'uludo',
  'uljani',
  'uljara',
  'uljast',
  'umaći',
  'umalo',
  'unići',
  'unija',
  'unuče',
  'unuka',
  'uopće',
  'upala',
  'upiti',
  'upliv',
  'upola',
  'uputa',
  'urbar',
  'ureći',
  'ureći',
  'urlik',
  'urota',
  'ushit',
  'usjek',
  'usjev',
  'uskok',
  'uspio',
  'uspon',
  'usput',
  'usred',
  'ustav',
  'usuti',
  'ušara',
  'ušata',
  'ušica',
  'ušiti',
  'ušljiv',
  'uštap',
  'utaći',
  'utaja',
  'uteći',
  'utiha',
  'utrka',
  'uvala',
  'uviti',
  'uvjet',
  'uvući',
  'uzdah',
  'uzduž',
  'uzduž',
  'uzeti',
  'uzgoj',
  'uzgon',
  'uzica',
  'uzlaz',
  'uzlet',
  'uzmah',
  'uznik',
  'uzrok',
  'uzvik',
  'užina',
  'uživo',
  'vagon',
  'valni',
  'valjak',
  'valjan',
  'valjda',
  'vapaj',
  'vapno',
  'varav',
  'varka',
  'varoš',
  'vatra',
  'vazal',
  'vazda',
  'važan',
  'večer',
  'vedar',
  'velar',
  'velik',
  'velim',
  'veoma',
  'vepar',
  'veseo',
  'veslo',
  'vesti',
  'vezir',
  'vidan',
  'vidik',
  'vidni',
  'vidno',
  'vidra',
  'viđen',
  'vihor',
  'vijak',
  'vijek',
  'vikar',
  'vinar',
  'vinil',
  'vinov',
  'vinjak',
  'viola',
  'virus',
  'visak',
  'viski',
  'visok',
  'višak',
  'više¹',
  'višnja',
  'vitak',
  'vitao',
  'vitez',
  'vitlo',
  'vjeđa',
  'vjera',
  'vješt',
  'vlada',
  'vlaga',
  'vlast',
  'voćar',
  'voćka',
  'voćni',
  'voden',
  'vodič',
  'vodik',
  'vodni',
  'vodni',
  'vojni',
  'vokal',
  'volak',
  'volan',
  'voljka',
  'voljni',
  'vosak',
  'votka',
  'vozač',
  'vozni',
  'vožnja',
  'vrana',
  'vrata',
  'vreća',
  'vrelo',
  'vrenje',
  'vreti',
  'vreva',
  'vrhnje',
  'vrlet',
  'vrsta',
  'vršak',
  'vršni',
  'vrtni',
  'vrtnja',
  'vrulja',
  'vučić',
  'vučji',
  'vučni',
  'zabat',
  'zabit',
  'začas',
  'začin',
  'zadah',
  'zadak',
  'zadnji',
  'zahod',
  'zajam',
  'zakon',
  'zakup',
  'zalaz',
  'zalet',
  'zalog',
  'zaljev',
  'zamah',
  'zamak',
  'zambi',
  'zamet',
  'zamka',
  'zamor',
  'zanat',
  'zanos',
  'zaova',
  'zapad',
  'zapis',
  'zapor',
  'zapuh',
  'zarez',
  'zašto',
  'zatim',
  'zaton',
  'zator',
  'zavod',
  'zavoj',
  'zbilja',
  'zbilja',
  'zbiti',
  'zbrka',
  'zbroj',
  'zdrav',
  'zebnja',
  'zebra',
  'zečić',
  'zečji',
  'zelen',
  'zelen',
  'zemlja',
  'zemni',
  'zenit',
  'zglob',
  'zgoda',
  'zidar',
  'zimus',
  'zipka',
  'zirak',
  'zjena',
  'zlato',
  'zloba',
  'zmija',
  'znanje',
  'znati',
  'zobun',
  'zoran',
  'zraka',
  'zreti',
  'zrnce',
  'zubac',
  'zubar',
  'zubat',
  'zubni',
  'zvanje',
  'zvati',
  'zvono',
  'žabac',
  'žablji',
  'žalac',
  'žalar',
  'žalba',
  'žamor',
  'žarki',
  'žarni',
  'žbica',
  'žbuka',
  'žbunje',
  'ždral',
  'žedan',
  'želva',
  'željan',
  'željen',
  'žemlja',
  'ženik',
  'ženka',
  'žeton',
  'žetva',
  'žezlo',
  'žgoljo',
  'žičan',
  'židak',
  'židov',
  'žilav',
  'žilet',
  'žilni',
  'živac',
  'živin',
  'živio',
  'život',
  'žlica',
  'žohar',
  'žrtva',
  'žrvanj',
  'žubor',
  'žučan',
  'žučni',
  'žućak',
  'žudnja',
  'žumor',
  'župan',
  'župni',
  'žuran',
  'žurba',
  'žurno',
  'žvaka',
]