import { CSSProperties } from "react";

export const styles: Record<string, CSSProperties> = {
  app: {
    // display: 'flex',
    // flexDirection: 'column' as 'column',
    // alignItems: 'center',
    // // justifyContent: 'center',
    width: '100vw',
    overflow: 'hidden',
    position: 'relative',
    paddingTop: 8,
    // height: '100vh',
  },
  container: {
    border: '1px solid rgb(155,155,155)',
    width: 40,
    height: 50,
    margin: 5,
    fontSize: 30,
    display: 'flex',
    textAlign: 'center' as 'center',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '1px 1px 2px 2px rgba(55,55,55,0.1)',
  },
  mainflexWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    margin: '0 auto',
  },
  guessesWrapper: {
    height: '100%',
    // maxHeight: '50vh',
    overflowY: 'scroll',
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  bravoPopup: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '5px',
    padding: '10px',
    boxShadow: '1px 1px 2px 2px rgba(55,55,55,0.1)',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80vw',
    height: '60vh',
    maxWidth: '600px',
    maxHeight: '600px',
    zIndex: 100,
  },
  betaBanner: {
    position: 'absolute',
    right: -100,
    top: -100,
    fontWeight: 'bold',
    color: 'red',
    backgroundColor: 'rgba(245, 39, 78, 0.4)',
    width: 180,
    height: 180,
    transform: 'rotate(45deg)'
  },
  betaText: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: 28,
    fontWeight: 'bold',
    color: 'red',
  },
  randomSwitch: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'white',
    fontSize: 20,
    padding: '5px',
    cursor: 'pointer',
  },
  explanerWindow: {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingTop: '40px',
    alignItems: 'center',
  },
  greebButton: {
    backgroundColor: '#6ff573',
    borderRadius: '5px',
    padding: '5px',
    margin: '5px',
    cursor: 'pointer',
    width: '100px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  },
}